<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Purposes</label>

          <v-select
            id="pointPurposes"
            :value="purposeFilter"
            :options="purposeOptions"
            multiple
            class="w-100"
            :reduce="title => title.id"
            label="title"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            @input="(val) => $emit('update:purposeFilter', val)"
          >
            <template #option="{ title, icon }">
              <feather-icon
                v-if="icon"
                :icon="icon"
                size="16"
                class="align-middle mr-50"
              />
              <span> {{ title }}</span>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    purposeFilter: {
      type: Array,
      default: null,
    },
    purposeOptions: {
      type: Array,
      required: true,
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
