import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Point from '@/mixins/classes/point'

export default function usePointsList() {
  // Use toast
  const toast = useToast()
  const refPointListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    {
      key: 'point_purpose',
      label: 'Purpose',
      sortable: true,
    },
    { key: 'estimated_time_of_stay', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalPoints = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const purposeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPointListTable.value ? refPointListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPoints.value,
    }
  })

  const refetchData = () => {
    refPointListTable.value.refresh()
  }

  watch([searchQuery, purposeFilter], () => {
    refetchData()
  })
  const deletePoint = point => {
    store.dispatch('point/deletePoint', { id: point.id })
      .then(response => {
        const successStatuses = [200, 201]
        if (successStatuses.includes(response.status)) {
          const index = refPointListTable.value.localItems.findIndex(item => item.id === point.id)
          if (index > -1) {
            refPointListTable.value.localItems.splice(index, 1)
          }
          // TODO use showToast mixin instead
          toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: `👋 Point - ${response.statusText}`,
              variant: 'success',
            },
          })
        }
      }).catch(response => {
        console.log(response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error point deleting',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchPoints = (ctx, callback) => {
    const payload = {
      q: searchQuery.value,
      per_page: perPage.value,
      page: currentPage.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
    }
    if (purposeFilter.value !== null) {
      payload.purpose_ids = purposeFilter.value.join()
    }
    if (sortBy.value === 'point_purpose') {
      payload.sort_by = 'point_purpose_id'
    } else {
      payload.sort_by = sortBy.value
    }
    store
      .dispatch('point/fetchPoints', payload)
      .then(response => {
        const { total } = response.data.meta
        callback(response.data.data.map($item => new Point($item)))
        totalPoints.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching points list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const formatTimeOfStay = num => {
    const hours = Math.floor(num / 60)
    const minutes = num % 60
    if (!hours) {
      return `${minutes} min`
    }
    return `${hours} hrs : ${minutes} min`
  }

  return {
    fetchPoints,
    tableColumns,
    perPage,
    currentPage,
    totalPoints,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPointListTable,

    refetchData,
    formatTimeOfStay,
    deletePoint,

    // Extra Filters
    purposeFilter,

  }
}
